import React from 'react';

const Logo = () => (
  <svg width="18" height="18" viewBox="0 0 373 373" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ color: 'var(--textColor)', fill: 'none' }}>
    <g clip-path="url(#clip0_1_3610)">
      <circle cx="186.94" cy="186.943" r="166.834" stroke="currentColor" stroke-width="4.22364"/>
      <path d="M107.41 221.494L186.325 109.612L265.566 222.7C236.72 230.981 213.837 235.06 189.932 234.911C165.93 234.761 140.78 230.348 107.41 221.494Z" fill="currentColor" stroke="currentColor" stroke-width="4.20817"/>
      <path d="M3.19934 216.112H55.9751C74.2012 216.112 92.3667 218.209 110.114 222.361L135.066 228.199C170.677 236.531 207.731 236.531 243.342 228.199L268.294 222.361C286.041 218.209 304.207 216.112 322.433 216.112H369.558" stroke="currentColor" stroke-width="4.20817"/>
      <path d="M3.19934 235.916H55.9751C74.2012 235.916 92.3667 238.012 110.114 242.165L135.066 248.003C170.677 256.335 207.731 256.335 243.342 248.003L268.294 242.165C286.041 238.012 304.207 235.916 322.433 235.916H369.558" stroke="currentColor" stroke-width="4.20817"/>
      <path d="M3.19934 255.719H55.9751C74.2012 255.719 92.3667 257.816 110.114 261.968L135.066 267.806C170.677 276.138 207.731 276.138 243.342 267.806L268.294 261.968C286.041 257.816 304.207 255.719 322.433 255.719H369.558" stroke="currentColor" stroke-width="4.20817"/>
      <path d="M3.19934 275.522H55.9751C74.2012 275.522 92.3667 277.619 110.114 281.771L135.066 287.609C170.677 295.941 207.731 295.941 243.342 287.609L268.294 281.771C286.041 277.619 304.207 275.522 322.433 275.522H369.558" stroke="currentColor" stroke-width="4.20817"/>
      <path d="M3.19934 295.325H55.9751C74.2012 295.325 92.3667 297.422 110.114 301.574L135.066 307.412C170.677 315.744 207.731 315.744 243.342 307.412L268.294 301.574C286.041 297.422 304.207 295.325 322.433 295.325H369.558" stroke="currentColor" stroke-width="4.20817"/>
      <path d="M3.19934 315.128H55.9751C74.2012 315.128 92.3667 317.225 110.114 321.377L135.066 327.215C170.677 335.547 207.731 335.547 243.342 327.215L268.294 321.377C286.041 317.225 304.207 315.128 322.433 315.128H369.558" stroke="currentColor" stroke-width="4.20817"/>
    </g>
    <rect x="20.1091" y="20.1094" width="332.43" height="332.43" rx="166.215" stroke="currentColor" stroke-width="4.22364"/>
    <defs>
      <clipPath id="clip0_1_3610">
        <rect x="17.9973" y="17.9976" width="336.654" height="336.654" rx="168.327" fill="currentColor"/>
      </clipPath>
    </defs>
  </svg>
);

export default Logo;