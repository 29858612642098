import React from 'react';

const XIcon = ({ onClick }) => (
  <svg
    onClick={onClick}
    width="26"
    height="26"
    viewBox="0 0 29 29"
    fill="#FFF"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer' }}
  >
    <g clipPath="url(#clip0_473_1215)">
      <path d="M21.8911 2.7002H25.8607L17.1883 12.6122L27.3907 26.1002H19.4023L13.1455 17.9198L5.98631 26.1002H2.01431L11.2903 15.4982L1.50311 2.7002H9.69431L15.3499 10.1774L21.8911 2.7002ZM20.4979 23.7242H22.6975L8.49911 4.9514H6.13871L20.4979 23.7242Z" fill="#FFF"/>
    </g>
    <defs>
      <clipPath id="clip0_473_1215">
        <rect width="28.8" height="28.8" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default XIcon;