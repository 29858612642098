// src/PriceChart.js
import './App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';

const PriceChart = ({ symbol, change, preloadedData }) => {
    const [chartData, setChartData] = useState(preloadedData || null);
    const [timeRange, setTimeRange] = useState(1); // Default to 1 day
    const [activeButton, setActiveButton] = useState(1); // Default active button

    useEffect(() => {
        if (preloadedData && timeRange === 1) {
            setChartData(preloadedData);
            return;
        }

        const fetchPriceData = async () => {
            try {
                const interval = timeRange === 1 ? '1h' : timeRange === 7 ? '1d' : timeRange === 30 ? '1d' : '7d'; // Set interval based on timeRange
                const response = await axios.post('/api/cmc-historical', {
                    symbol,
                    convert: 'USD',
                    time_start: new Date(Date.now() - timeRange * 24 * 60 * 60 * 1000).toISOString(),
                    time_end: new Date().toISOString(),
                    interval
                });

                const data = response.data.data[symbol][0].quotes.map(entry => ({
                    date: new Date(entry.timestamp).toISOString(),
                    price: entry.quote.USD.price
                }));

                setChartData(data);
            } catch (error) {
                console.error('Error fetching data:', error.response ? error.response.data : error.message);
            }
        };

        fetchPriceData();
    }, [symbol, timeRange, preloadedData]);

    const handleButtonClick = (range) => {
        setTimeRange(range);
        setActiveButton(range);
    };

    return (
        <div data-testid="price-chart">
            {chartData ? (
                <ResponsiveContainer width="100%" height={250}>
                    <AreaChart data={chartData} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                        <defs>
                            <linearGradient id="lineGradient" x1="0" y1="0" x2="1" y2="0">
                                <stop offset="0%" stopColor="#167194" />
                                <stop offset="25%" stopColor="#167194" />
                                <stop offset="50%" stopColor="#167194" />
                                <stop offset="75%" stopColor="#167194" />
                            </linearGradient>
                            <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="#167194" stopOpacity={0.4} />
                                <stop offset="100%" stopColor="#167194" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
                        <XAxis
                            dataKey="date"
                            tickFormatter={(date) =>
                                timeRange <= 2
                                    ? new Date(date).toLocaleTimeString('default', { hour: '2-digit' })
                                    : new Date(date).toLocaleString('default', { month: 'short', day: '2-digit' })
                            }
                            tick={{ fontSize: 12 }}
                            interval={Math.ceil(chartData.length / 3)} // Adjust interval to reduce ticks
                            tickCount={Math.min(3, chartData.length)} // Reduce the number of ticks
                            axisLine={false} // Remove X axis line
                            tickLine={false} // Remove X axis tick lines
                        />
                        <YAxis
                            domain={['auto', 'auto']}
                            width={40}
                            tick={{ fontSize: 12 }}
                            orientation="right"
                            axisLine={false} // Remove Y axis line
                            tickLine={false} // Remove Y axis tick lines
                        />
                        <Tooltip
                            formatter={(value) => [`$${value.toFixed(4)}`, 'Price']}
                            labelFormatter={(label) => 
                                timeRange === 1 
                                    ? new Date(label).toLocaleTimeString('default', { hour: '2-digit' }) 
                                    : new Date(label).toLocaleString()
                            }
                            contentStyle={{ backgroundColor: '#001f3f', color: '#ffffff' }} // Dark blue background, white text
                            itemStyle={{ color: '#ffffff' }} // Ensure item text is white
                        />
                        <Area
                            type="monotone"
                            dataKey="price"
                            stroke="url(#lineGradient)"
                            fill="url(#areaGradient)"
                            fillOpacity={1}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            ) : (
                <p>Loading chart...</p>
            )}
            <div className='graphButton-container'>
                <button
                    className={`graphButton ${activeButton === 1 ? 'active' : ''}`}
                    onClick={() => handleButtonClick(1)}
                >
                    1D
                </button>
                <button
                    className={`graphButton ${activeButton === 7 ? 'active' : ''}`}
                    onClick={() => handleButtonClick(7)}
                >
                    1W
                </button>
                <button
                    className={`graphButton ${activeButton === 30 ? 'active' : ''}`}
                    onClick={() => handleButtonClick(30)}
                >
                    1M
                </button>
                <button
                    className={`graphButton ${activeButton === 365 ? 'active' : ''}`}
                    onClick={() => handleButtonClick(365)}
                >
                    1Y
                </button>
            </div>
        </div>
    );
};

export default PriceChart;