import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import './loading.css';
import './wave.css';
import PriceChart from './PriceChart';
import TradingViewWidget from './TradingViewWidget';
import WebsiteIcon from './components/WebsiteIcon';
import XIcon from './components/XIcon';
import RocketIcon from './components/RocketIcon';
import Logo from './components/logo';
import { FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

function App() {
  const [activeTab, setActiveTab] = useState('Details');
  const [assetData, setAssetData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [params] = useSearchParams();
  const [colorScheme, setColorScheme] = useState(() => {
    // Initialize theme once during component mount
    const savedTheme = localStorage.getItem('theme') || 'dark';
    document.body.setAttribute('data-theme', savedTheme);
    return savedTheme;
  });
  const [preloadedChartData, setPreloadedChartData] = useState(null);
  const [summaryLoaded, setSummaryLoaded] = useState(false);

  // Theme management
  useEffect(() => {
    if (typeof window !== 'undefined' && window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      const tgTheme = window.Telegram.WebApp.colorScheme;
      setColorScheme(tgTheme);
      document.body.setAttribute('data-theme', tgTheme);
      localStorage.setItem('theme', tgTheme);
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.setHeaderColor('#0B2A37');
    } else {
      console.warn('Telegram WebApp is not available');
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = colorScheme === 'dark' ? 'light' : 'dark';
    setColorScheme(newTheme);
    document.body.setAttribute('data-theme', newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      setColorScheme(window.Telegram.WebApp.colorScheme);
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.setHeaderColor('#0B2A37');
    } else {
      console.warn('Telegram WebApp is not available');
    }

    const projectId = params.get('tgWebAppStartParam') || '2789';
    const apiKey = process.env.REACT_APP_API_KEY;

    const fetchAssetData = async () => {
      setLoading(true);
      setError(null);

      try {
        const headers = {
          'Content-Type': 'application/json',
          'language': 'en',
          'apikey': apiKey
        };

        const response = await axios.post('https://info.arcticdigital.info/api/details',
          { projectId: projectId },
          { headers }
        );

        if (response.data) {
          setAssetData(response.data);
          if (response.data.token_symbol) {
            try {
              console.log('Fetching chart data with params:', {
                symbol: response.data.token_symbol,
                cmcProjectId: response.data.social_media?.cmc,
                timeRange: {
                  start: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).toISOString(),
                  end: new Date().toISOString()
                }
              });

              const chartResponse = await axios.post('/api/cmc-historical', {
                symbol: response.data.token_symbol,
                cmcProjectId: response.data.social_media?.cmc,
                convert: 'USD',
                time_start: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).toISOString(),
                time_end: new Date().toISOString(),
                interval: '1h'
              });
              
              console.log('Raw chart response:', JSON.stringify(chartResponse.data, null, 2));
              
              // Handle both data structures
              let processedData;
              const currentTime = new Date();
              const oneDayAgo = new Date(currentTime - 24 * 60 * 60 * 1000);

              if (chartResponse.data?.data?.quotes) {
                // Structure 1: direct quotes array
                processedData = chartResponse.data.data.quotes
                  .map(entry => {
                    const timestamp = new Date(entry.timestamp);
                    // Validate timestamp is within expected range
                    if (timestamp > oneDayAgo && timestamp <= currentTime) {
                      return {
                        date: timestamp.toISOString(),
                        price: entry.quote?.USD?.price || 0
                      };
                    }
                    return null;
                  })
                  .filter(entry => entry !== null);
              } else if (chartResponse.data?.data?.[response.data.token_symbol]) {
                // Structure 2: nested under token symbol
                const tokenData = chartResponse.data.data[response.data.token_symbol];
                processedData = tokenData[0].quotes
                  .map(entry => {
                    const timestamp = new Date(entry.timestamp);
                    // Validate timestamp is within expected range
                    if (timestamp > oneDayAgo && timestamp <= currentTime) {
                      return {
                        date: timestamp.toISOString(),
                        price: entry.quote?.USD?.price || 0
                      };
                    }
                    return null;
                  })
                  .filter(entry => entry !== null);
              }

              if (processedData?.length > 0) {
                console.log('Processed chart data (first 2 entries):', processedData.slice(0, 2));
                console.log('Total valid data points:', processedData.length);
                
                // Calculate 24h price change
                const latestPrice = processedData[processedData.length - 1].price;
                const earliestPrice = processedData[0].price;
                const priceChange = ((latestPrice - earliestPrice) / earliestPrice) * 100;
                
                console.log('24h Price Change Calculation:', {
                  latestPrice,
                  earliestPrice,
                  percentageChange: priceChange.toFixed(2)
                });
                
                setPreloadedChartData(processedData);
                // Update the asset data with the calculated price change
                setAssetData(prevData => ({
                  ...prevData,
                  price_change_24h: priceChange.toFixed(2)
                }));
              } else {
                console.error('Invalid chart data structure or no valid data points:', {
                  dataStructure: chartResponse.data,
                  processedDataLength: processedData?.length || 0
                });
                setPreloadedChartData([]);
              }
            } catch (chartError) {
              console.error('Error fetching chart data:', chartError);
              // Don't set error state here as it's not critical
            }
          }
          setSummaryLoaded(true);
        } else {
          setError('No data found for the provided asset name');
          setSummaryLoaded(true);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.response?.data?.error || 'Error fetching data. Please try again.');
        setSummaryLoaded(true);
      } finally {
        setLoading(false);
      }
    };

    fetchAssetData();
  }, [params]);

  useEffect(() => {
    console.log('colorScheme:', colorScheme);
  }, [colorScheme]);

  const openExternalLink = (url) => {
    console.log('Opening external link:', url);
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink(url);
    } else {
      window.open(url, '_blank');
    }
  };

  const openTelegramLink = (url) => {
    console.log('Opening Telegram link:', url);
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openTelegramLink(url);
    } else {
      window.open(url, '_blank');
    }
  }

  const requestContact = () => {
    console.log('Requesting Contact');
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.requestContact();
    } else {
      console.warn('Telegram WebApp is not available');
    }
  }

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const contactRequestedHandler = (contact) => {
        console.log('Contact requested:', contact);
      };

      window.Telegram.WebApp.onEvent('contactRequested', contactRequestedHandler);

      return () => {
        window.Telegram.WebApp.offEvent('contactRequested', contactRequestedHandler);
      };
    }
  }, []);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
      script.innerHTML = JSON.stringify({
        symbol: "OKX:ATHUSDT",
        width: 350,
        height: 220,
        locale: "en",
        dateRange: "12M",
        colorTheme: colorScheme === 'light' ? 'light' : 'dark',
        isTransparent: false,
        autosize: false,
        largeChartUrl: "",
        chartOnly: false,
        noTimeScale: false
      });

      const container = document.querySelector('.tradingview-widget-container__widget');
      if (container) {
        container.innerHTML = '';
        container.appendChild(script);
      }
    }
  }, [colorScheme]);

  return (
    <div className="app-container">
      <div className="content-wrapper">
        {(loading || !summaryLoaded) && (
          <div className={`loadingWrapper ${colorScheme === 'light' ? 'light-mode' : 'dark-mode'}`}>
            <RocketIcon className="loadingImage" />
          </div>
        )}
        {error && (
          <div className="error-container">
            <p className="error">{error}</p>
            <button onClick={() => window.location.reload()} className="retry-button">
              Retry
            </button>
          </div>
        )}
        {!loading && summaryLoaded && assetData && (
          <div className="content-area">
            <div id="details">
              <div className="sticky-header">
                <div className="asset-header no-rounded-corner">
                  <div className="asset-logo-wrapper">
                    <div className="underlay-circle"></div>
                    <img src={assetData.logo} alt={assetData.project_name} className="asset-logo" />
                  </div>
                  <div className="asset-info">
                    <h2>{assetData.project_name}</h2>
                    <p>{assetData.one_liner}</p>
                    <div className="social-media">
                      {Object.entries(assetData.social_media)
                        .filter(([platform]) => platform !== 'cmc')
                        .map(([platform, link]) => (
                        link ? (
                          platform === 'X' ? (
                            <XIcon 
                              className="social-media-button" 
                              key={platform} 
                              onClick={() => openExternalLink(link)} 
                              theme={{ fillColor: colorScheme === 'dark' ? '#ffffff' : '#0f232e' }}
                            />
                          ) : platform === 'website' ? (
                            <WebsiteIcon 
                              className="social-media-button" 
                              key={platform} 
                              onClick={() => openExternalLink(link)} 
                              theme={{ fillColor: colorScheme === 'dark' ? '#ffffff' : '#0f232e' }}
                            />
                          ) : (
                            <button
                              key={platform}
                              onClick={() => openExternalLink(link)}
                              className={`social-media-button ${platform}`}
                            >
                              <i className={`fab fa-${platform}`}></i> {platform}
                            </button>
                          )
                        ) : null
                      ))}
                    </div>
                  </div>
                  <button onClick={toggleTheme} className="theme-toggle-button">
                    {colorScheme === 'dark' ? '☀️' : '🌙'}
                  </button>
                </div>

                <div className="tabs continuous-bar">
                  <button
                    className={activeTab === 'Details' ? 'active' : ''}
                    onClick={() => setActiveTab('Details')}
                  >
                    Details
                  </button>
                  {assetData.fundraising?.items?.length > 0 && (
                    <button
                      className={activeTab === 'Fundraising' ? 'active' : ''}
                      onClick={() => setActiveTab('Fundraising')}
                    >
                      Fundraising
                    </button>
                  )}
                  {assetData.event?.length > 0 && (
                    <button
                      className={activeTab === 'Events' ? 'active' : ''}
                      onClick={() => setActiveTab('Events')}
                    >
                      Events
                    </button>
                  )}
                  {assetData.team_members?.length > 0 && (
                    <button
                      className={activeTab === 'Team' ? 'active' : ''}
                      onClick={() => setActiveTab('Team')}
                    >
                      Team
                    </button>
                  )}
                  <button
                    className={activeTab === 'Social' ? 'active' : ''}
                    onClick={() => setActiveTab('Social')}
                  >
                    Social
                  </button>
                  {
                    assetData.token_symbol && (
                      <button
                        className={activeTab === 'Market' ? 'active' : ''}
                        onClick={() => setActiveTab('Market')}
                      >
                        Market
                      </button>
                    )
                  }
                </div>
              </div>

              {activeTab === 'Details' && (
                <>
                  <div id="summary">
                    <p> {assetData.description}</p>
                    <div className="tags">
                      {assetData.tags.map((tag, index) => (
                        <button key={index} className="tag-button">{tag}</button>
                      ))}
                    </div>

                    {assetData.investors.length > 0 && (
                      <>
                        <h3>Investors:</h3>
                        <ul>
                          {assetData.investors.map((investor, index) => (
                            <li key={index} className="investor">
                              <img src={investor.logo} alt={investor.name} className="investor-logo" />
                              <div>
                                <strong>{investor.name}</strong>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </>
              )}
              {activeTab === 'Fundraising' && (
                <div id="fundraising">
                  <div className="total-funding">
                    Total Funding: ${assetData.total_funding ? assetData.total_funding.toLocaleString() : 'N/A'}
                  </div>
                  <table className="fundraising-table">
                    <thead>
                      <tr>
                        <th>Round</th>
                        <th>Amount</th>
                        <th>Valuation</th>
                        <th>Date</th>
                        <th>Investors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assetData.fundraising.items.map((item, index) => (
                        <tr key={index}>
                          <td>{item.rounds}</td>
                          <td>${item.amount ? item.amount.toLocaleString() : 'N/A'}</td>
                          <td>${item.valuation ? item.valuation.toLocaleString() : 'N/A'}</td>
                          <td>{new Date(item.published_time).toLocaleDateString()}</td>
                          <td>
                            <div className="investor-list">
                              {item.invests.reduce((rows, investor, investIndex) => {
                                if (investIndex % 5 === 0) {
                                  rows.push([]);
                                }
                                rows[rows.length - 1].push(
                                  <div key={investIndex} className="investor-cell">
                                    <img src={investor.logo} alt={investor.name} className="investor-logo" />
                                    <span>{investor.name}</span>
                                  </div>
                                );
                                return rows;
                              }, []).map((row, rowIndex) => (
                                <div key={rowIndex} className="investor-row">
                                  {row}
                                </div>
                              ))}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {activeTab === 'Events' && (
                <div id="events">
                  <table className="events-table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Event</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assetData.event.map((event, index) => (
                        <tr key={index}>
                          <td>{new Date(event.hap_date).toLocaleDateString()}</td>
                          <td>{event.event}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {activeTab === 'Team' && assetData.team_members?.length > 0 && (
                <div id="team">
                  <div className="team-list">
                    {assetData.team_members.map((member, index) => (
                      <div key={index} className="team-member">
                        <div className="member-info">
                          <img src={member.head_img} alt={member.name} className="member-avatar" />
                          <div className="member-details">
                            <div className="member-name">{member.name}</div>
                            <div className="member-position">{member.position}</div>
                          </div>
                          <div className="member-social">
                            {member.linkedin && (
                              <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="social-icon">
                                <FaLinkedin />
                              </a>
                            )}
                            {member.X && (
                              <a href={member.X} target="_blank" rel="noopener noreferrer" className="social-icon">
                                <FaXTwitter />
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {activeTab === 'Social' && (
                <div id="social">
                  <div className="social-stats">
                    <div className="stat-item">
                      <span className="stat-label">Influence Rank</span>
                      <span className="stat-value">{assetData.influence_rank}</span>
                    </div>
                    <div className="stat-item">
                      <span className="stat-label">Top Followers</span>
                      <span className="stat-value">{assetData.top_followers}</span>
                    </div>
                    <div className="stat-item">
                      <span className="stat-label">Followers</span>
                      <span className="stat-value">{assetData.followers?.toLocaleString()}</span>
                    </div>
                    <div className="stat-item">
                      <span className="stat-label">Following</span>
                      <span className="stat-value">{assetData.following?.toLocaleString()}</span>
                    </div>
                  </div>
                  <div className="social-links">
                    {assetData.social_media?.website && (
                      <a href={assetData.social_media.website} target="_blank" rel="noopener noreferrer" className="social-link">
                        <i className="fas fa-globe"></i>
                        <span>Website</span>
                      </a>
                    )}
                    {assetData.social_media?.X && (
                      <a href={assetData.social_media.X} target="_blank" rel="noopener noreferrer" className="social-link">
                        <i className="fab fa-x-twitter"></i>
                        <span>Twitter</span>
                      </a>
                    )}
                    {assetData.social_media?.medium && (
                      <a href={assetData.social_media.medium} target="_blank" rel="noopener noreferrer" className="social-link">
                        <i className="fab fa-medium"></i>
                        <span>Medium</span>
                      </a>
                    )}
                  </div>
                </div>
              )}

              {activeTab === 'Market' && assetData.token_symbol && (
                <div id="market">
                  <h2><strong>{assetData.token_symbol}</strong></h2>
                  <div className="market-data-grid">
                    <div className="market-data-item">
                      <h3>Price Change (24h)</h3>
                      <p className={parseFloat(assetData.price_change_24h || 0) >= 0 ? 'positive' : 'negative'}>
                        {assetData.price_change_24h ? `${assetData.price_change_24h}%` : '0.00%'}
                      </p>
                    </div>
                    <div className="market-data-item">
                      <h3>Volume (24h)</h3>
                      <p>
                        {assetData.cmc?.quote?.USD?.volume_24h 
                          ? `$${Number(assetData.cmc.quote.USD.volume_24h).toLocaleString()}`
                          : 'N/A'}
                      </p>
                    </div>
                    <div className="market-data-item">
                      <h3>Market Cap</h3>
                      <p>
                        {assetData.cmc?.quote?.USD?.market_cap
                          ? `$${Number(assetData.cmc.quote.USD.market_cap).toLocaleString()}`
                          : 'N/A'}
                      </p>
                    </div>
                    {assetData.cmc?.quote?.USD?.circulating_supply && (
                      <div className="market-data-item">
                        <h3>Circulating Supply</h3>
                        <p>{Number(assetData.cmc.quote.USD.circulating_supply).toLocaleString()} {assetData.token_symbol}</p>
                      </div>
                    )}
                    {assetData.cmc?.quote?.USD?.total_supply && (
                      <div className="market-data-item">
                        <h3>Total Supply</h3>
                        <p>{Number(assetData.cmc.quote.USD.total_supply).toLocaleString()} {assetData.token_symbol}</p>
                      </div>
                    )}
                  </div>
                  
                  <div className="chart-container">
                    <PriceChart
                      symbol={assetData.token_symbol}
                      change={assetData.price_change_24h}
                      preloadedData={preloadedChartData}
                    />
                  </div>
                </div>
              )}

              {activeTab === 'TradingView' && (
                <div id="tradingview">
                  <TradingViewWidget ticker={assetData.token_symbol} /> 
                </div>
              )}

              {activeTab === 'SimilarProjects' && (
                <div id="similar-projects">
                  <h3>Similar Projects:</h3>
                  <ul>
                    {assetData.similar_project.map((project, index) => (
                      <li key={index} className="similar-project">
                        <img onClick={() => openTelegramLink("https://t.me/arctic_digital_info_bot/assetInfo?startapp=" + project.project_name)} src={project.logo} alt={project.project_name} className="similar-project-logo" />
                        <div>
                          <strong>{project.project_name}</strong>: {project.brief_description}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

            </div>
          </div>
        )}
      </div>
      <footer className="footer">
        <div className="footer-content">
          <p> <Logo /> Arctic Digital 2024 | Powered by <img src="https://www.rootdata.com/_nuxt/img/logo.7e97cac.png" alt="RootData" style={{ height: '20px', verticalAlign: 'middle' }} /></p>
        </div>
      </footer>
    </div>
  );
}

export default App;
