import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import './loading.css';
import './wave.css';
import PriceChart from './PriceChart';
import TradingViewWidget from './TradingViewWidget';
import WebsiteIcon from './components/WebsiteIcon';
import XIcon from './components/XIcon';
import RocketIcon from './components/RocketIcon';
import Logo from './components/logo';


function App() {
  const [activeTab, setActiveTab] = useState('Summary');
  const [assetData, setAssetData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [params] = useSearchParams();
  const [colorScheme, setColorScheme] = useState('light');
  const [preloadedChartData, setPreloadedChartData] = useState(null);
  const [summaryLoaded, setSummaryLoaded] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      setColorScheme(window.Telegram.WebApp.colorScheme);
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.setHeaderColor('#0B2A37');
    } else {
      console.warn('Telegram WebApp is not available');
    }

    const asset = params.get('tgWebAppStartParam') || 'Aethir';
    const apiKey = process.env.REACT_APP_API_KEY;

    const fetchAssetData = async () => {
      setLoading(true);
      setError(null);

      try {
        const headers = {
          'Content-Type': 'application/json',
          'language': 'en',
          'apikey': apiKey
        };

        const response = await axios.post('https://info.arcticdigital.info/api/details',
          { asset: asset },
          { headers }
        );

        if (response.data) {
          setAssetData(response.data);
          const chartResponse = await axios.post('/api/cmc-historical', {
            symbol: response.data.token_symbol,
            convert: 'USD',
            time_start: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).toISOString(),
            time_end: new Date().toISOString(),
            interval: '1h'
          });
          const data = chartResponse.data.data[response.data.token_symbol][0].quotes.map(entry => ({
            date: new Date(entry.timestamp).toISOString(),
            price: entry.quote.USD.price
          }));
          setPreloadedChartData(data);
          setSummaryLoaded(true);
        } else {
          setError('No data found for the provided asset name');
        }
      } catch (err) {
        setError('Error fetching data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchAssetData();
  }, [params]);

  useEffect(() => {
    console.log('colorScheme:', colorScheme);
  }, [colorScheme]);

  const openExternalLink = (url) => {
    console.log('Opening external link:', url);
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink(url);
    } else {
      window.open(url, '_blank');
    }
  };

  const openTelegramLink = (url) => {
    console.log('Opening Telegram link:', url);
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openTelegramLink(url);
    } else {
      window.open(url, '_blank');
    }
  }

  const requestContact = () => {
    console.log('Requesting Contact');
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.requestContact();
    } else {
      console.warn('Telegram WebApp is not available');
    }
  }

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const contactRequestedHandler = (contact) => {
        console.log('Contact requested:', contact);
      };

      window.Telegram.WebApp.onEvent('contactRequested', contactRequestedHandler);

      return () => {
        window.Telegram.WebApp.offEvent('contactRequested', contactRequestedHandler);
      };
    }
  }, []);

  useEffect(() => {
    if (activeTab === 'Market' && assetData.cmc) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
      script.innerHTML = JSON.stringify({
        symbol: "OKX:ATHUSDT",
        width: 350,
        height: 220,
        locale: "en",
        dateRange: "12M",
        colorTheme: colorScheme === 'light' ? 'light' : 'dark',
        isTransparent: false,
        autosize: false,
        largeChartUrl: "",
        chartOnly: false,
        noTimeScale: false
      });

      const container = document.querySelector('.tradingview-widget-container__widget');
      if (container) {
        container.innerHTML = '';
        container.appendChild(script);
      }
    }
  }, [activeTab, assetData, colorScheme]);

  return (
    <div className="App">
      {(loading || !summaryLoaded) && (
        <div className={`loadingWrapper ${colorScheme === 'light' ? 'light-mode' : 'dark-mode'}`}>
          <RocketIcon className="loadingImage" />
        </div>
      )}
      {!loading && summaryLoaded && assetData && (
        <div id="details">
          <div className="asset-header no-rounded-corner">
            <div className="asset-logo-wrapper">
              <div className="underlay-circle"></div>
              <img src={assetData.logo} alt={assetData.project_name} className="asset-logo" />
            </div>
            <div className="asset-info">
              <h2>{assetData.project_name}</h2>
              <p>{assetData.one_liner}</p>
              <div className="social-media">
                {Object.entries(assetData.social_media).map(([platform, link]) => (
                  link ? (
                    platform === 'X' ? (
                      <XIcon className="social-media-button" key={platform} onClick={() => openExternalLink(link)} />
                    ) : platform === 'website' ? (
                      <WebsiteIcon className="social-media-button" key={platform} onClick={() => openExternalLink(link)} />
                    ) : (
                      <button
                        key={platform}
                        onClick={() => openExternalLink(link)}
                        className={`social-media-button ${platform}`}
                      >
                        <i className={`fab fa-${platform}`}></i> {platform}
                      </button>
                    )
                  ) : null
                ))}
              </div>
            </div>
          </div>

          <div className="tabs continuous-bar">
            <button
              className={activeTab === 'Summary' ? 'active' : ''}
              onClick={() => setActiveTab('Summary')}
            >
              Summary
            </button>
            {assetData.fundraising && assetData.fundraising.items.length > 0 && (
              <button
                className={activeTab === 'Fundraising' ? 'active' : ''}
                onClick={() => setActiveTab('Fundraising')}
              >
                Fundraising
              </button>
            )}
            {assetData.cmc && (
              <button
                className={activeTab === 'Market' ? 'active' : ''}
                onClick={() => setActiveTab('Market')}
              >
                Market
              </button>
            )}
            {/* <button
              className={activeTab === 'TradingView' ? 'active' : ''}
              onClick={() => setActiveTab('TradingView')}
            >
              TradingView
            </button> */}
            <button
              className={activeTab === 'SimilarProjects' ? 'active' : ''}
              onClick={() => setActiveTab('SimilarProjects')}
            >
              Similar
            </button>
          </div>

          {activeTab === 'Summary' && (
            <>
              <div id="summary">
                <p> {assetData.description}</p>
                <div className="tags">
                  {assetData.tags.map((tag, index) => (
                    <button key={index} className="tag-button">{tag}</button>
                  ))}
                </div>

                {assetData.investors.length > 0 && (
                  <>
                    <h3>Investors:</h3>
                    <ul>
                      {assetData.investors.map((investor, index) => (
                        <li key={index} className="investor">
                          <img src={investor.logo} alt={investor.name} className="investor-logo" />
                          <div>
                            <strong>{investor.name}</strong>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </>
          )}
          {activeTab === 'Fundraising' && (
            <div id="fundraising">
              {assetData.fundraising.items.map((item, index) => (
                <div key={index} className="round">
                  <p>
                    <strong>Amount Raised:</strong> $
                    {item.amount ? item.amount.toLocaleString() : 'N/A'}
                  </p>
                  <p>
                    <strong>Valuation:</strong> $
                    {item.valuation ? item.valuation.toLocaleString() : 'N/A'}
                  </p>
                  <p><strong>Published Time:</strong> {new Date(item.published_time).toLocaleDateString()}</p>
                  <p><strong>Round:</strong> {item.rounds}</p>
                  <h5>Investors:</h5>
                  <ul>
                    {item.invests.map((investor, investIndex) => (
                      <li key={investIndex} className="investor">
                        <img src={investor.logo} alt={investor.name} className="investor-logo" />
                        <div>
                          <a href={investor.rootdataurl} target="_blank" rel="noopener noreferrer">
                            <strong>{investor.name}</strong>
                          </a>
                          {investor.lead_investor ? <span> (Lead Investor)</span> : null}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}

          {activeTab === 'Market' && assetData.cmc && (
            <div id="market">
              <h2><strong>{assetData.token_symbol}</strong></h2>

              {assetData.cmc.quote?.USD?.price !== undefined && (
                <h3>${assetData.cmc.quote.USD.price.toFixed(6)} (USD)</h3>
              )}
              <PriceChart symbol={assetData.token_symbol} change={assetData.cmc.quote.USD.percent_change_24h.toFixed(2)} preloadedData={preloadedChartData} />
              {assetData.cmc.quote?.USD?.market_cap !== undefined && (
                <p><strong>Market Cap (USD):</strong> ${assetData.cmc.quote.USD.market_cap.toFixed(2)}</p>
              )}
              {assetData.cmc.quote?.USD?.volume_24h !== undefined && (
                <p><strong>Volume (24h):</strong> ${assetData.cmc.quote.USD.volume_24h.toFixed(2)}</p>
              )}
              {assetData.cmc.quote?.USD?.percent_change_24h !== undefined && (
                <p><strong>Change (24h):</strong> {assetData.cmc.quote.USD.percent_change_24h.toFixed(2)}%</p>
              )}
            </div>
          )}

          {activeTab === 'TradingView' && (
            <div id="tradingview">
              <TradingViewWidget ticker={assetData.token_symbol} /> 
            </div>
          )}

          {activeTab === 'SimilarProjects' && (
            <div id="similar-projects">
              <h3>Similar Projects:</h3>
              <ul>
                {assetData.similar_project.map((project, index) => (
                  <li key={index} className="similar-project">
                    <img onClick={() => openTelegramLink("https://t.me/arctic_digital_info_bot/assetInfo?startapp=" + project.project_name)} src={project.logo} alt={project.project_name} className="similar-project-logo" />
                    <div>
                      <strong>{project.project_name}</strong>: {project.brief_description}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}

        </div>
      )}
      {error && !assetData && <p className="error">{error}</p>}

      {!loading && (
        <footer className="footer">
          <Logo />
          <p>Arctic Digital 2024 | All Rights Reserved</p>
        </footer>
      )}
    </div>
  );
}

export default App;
