import * as React from "react"
const RocketIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    width={200}
    height={200}
    x={0}
    y={0}
    preserveAspectRatio="xMidYMid"
    style={{
      margin: "initial",
      display: "block",
      shapeRendering: "auto",
      background: "transparent",
    }}
    viewBox="0 0 100 100"
    {...props}
  >
    <g
      className="ldl-scale"
      style={{
        transformOrigin: "50% 50%",
        transform: "rotate(0deg) scale(.8,.8)",
      }}
    >
      <g className="ldl-ani">
        <g className="ldl-layer">
          <g className="ldl-ani">
            <g
              className="ldl-layer"
              style={{
                opacity: 1,
                transformOrigin: "50px 50px",
                transform: "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                animation:
                  "1s linear -.525s infinite normal forwards running animate",
                transformBox: "view-box",
              }}
            >
              <path
                fill="#FEDC00"
                d="M45.4 54.2c-1.7-.6-4.1 0-5.9 1.7-1.9 1.7-2.8 4-2.8 6 0 .9-.4 1.9-1 2.7l-.1.1c-.8 1-1.3 2.1-1.6 3-.4 1.4-.9 2.8-2 4l-.1.1c-1.1 1.4-2.3 2.5-3.3 3.9-1 1.5-1.9 2.8-2.6 4.5l.1.1c1.4-1.1 2.6-2.3 3.8-3.6 1.2-1.3 2-2.7 3.1-4.1l.1-.1c.9-1.4 2.1-2.2 3.4-2.9.9-.4 1.7-1.3 2.6-2.3l.1-.1c.6-.8 1.5-1.4 2.4-1.6 1.5-.3 3.2-1.4 4.4-2.9 2.3-2.9 2.5-6.5.4-8.1-.4 0-.7-.1-1-.4z"
                className="ldl-ani"
                style={{
                  strokeWidth: 1,
                  fill: "#fffadc",
                }}
              />
            </g>
            <g className="ldl-layer">
              <g className="ldl-ani">
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.55s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <ellipse
                        cx={37.5}
                        cy={72.9}
                        fill="#FEDC00"
                        className="ldl-ani"
                        rx={5.1}
                        ry={0.7}
                        style={{
                          strokeWidth: 1,
                          fill: "#fffadc",
                        }}
                        transform="rotate(-51.745 37.532 72.935)"
                      />
                    </g>
                  </g>
                </g>
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.575s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <ellipse
                        cx={27.8}
                        cy={71.8}
                        fill="#FEDC00"
                        className="ldl-ani"
                        rx={5.1}
                        ry={0.7}
                        style={{
                          strokeWidth: 1,
                          fill: "#fffadc",
                        }}
                        transform="rotate(-51.745 27.768 71.788)"
                      />
                    </g>
                  </g>
                </g>
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.6s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <ellipse
                        cx={28.8}
                        cy={81.6}
                        fill="#FEDC00"
                        className="ldl-ani"
                        rx={4.3}
                        ry={0.7}
                        style={{
                          strokeWidth: 1,
                          fill: "#fffadc",
                        }}
                        transform="rotate(-51.745 28.825 81.605)"
                      />
                    </g>
                  </g>
                </g>
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.625s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <ellipse
                        cx={22.1}
                        cy={82.5}
                        fill="#FEDC00"
                        className="ldl-ani"
                        rx={3.2}
                        ry={0.7}
                        style={{
                          strokeWidth: 1,
                          fill: "#fffadc",
                        }}
                        transform="rotate(-51.745 22.128 82.502)"
                      />
                    </g>
                  </g>
                </g>
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.65s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <ellipse
                        cx={21.3}
                        cy={87.8}
                        fill="#FEDC00"
                        className="ldl-ani"
                        rx={3.2}
                        ry={0.7}
                        style={{
                          strokeWidth: 1,
                          fill: "#fffadc",
                        }}
                        transform="rotate(-51.745 21.294 87.833)"
                      />
                    </g>
                  </g>
                </g>
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.675s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <ellipse
                        cx={14.5}
                        cy={93.3}
                        fill="#FEDC00"
                        className="ldl-ani"
                        rx={2.2}
                        ry={0.7}
                        style={{
                          strokeWidth: 1,
                          fill: "#fffadc",
                        }}
                        transform="rotate(-51.745 14.515 93.344)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g className="ldl-layer">
          <g className="ldl-ani">
            <g className="ldl-layer">
              <g className="ldl-ani">
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.7s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <path
                        fill="#676767"
                        d="m57.8 47.9-7.6-6L39 52.6l11.2 8.8 7.6-13.5z"
                        className="ldl-ani"
                        style={{
                          strokeWidth: 1,
                          fill: "#dcdcdc",
                        }}
                      />
                    </g>
                  </g>
                </g>
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.725s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <path
                        fill="#010101"
                        d="M53.9 53.3c-5.2.9-10.1 0-13.6-2.3L39 52.4l11.2 8.8 4.7-8.1c-.3.1-.5.2-1 .2z"
                        className="ldl-ani"
                        opacity={0.1}
                        style={{
                          strokeWidth: 1,
                          fill: "#010101",
                        }}
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g className="ldl-layer">
              <g className="ldl-ani">
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.75s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <path
                        fill="#E0E1E0"
                        d="M63.6 13.6 52.3 27.9 40.6 44.1 58.3 58l12.8-14.8 11.6-14.7.7-.9-18.9-14.8c-.3.3-.7.5-.9.8z"
                        className="ldl-ani"
                        style={{
                          strokeWidth: 1,
                          fill: "#eb5d21",
                        }}
                      />
                    </g>
                  </g>
                </g>
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.775s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <path
                        fill="#010101"
                        d="m43.8 42.2-1.2-.9-2.1 2.9L58.3 58l12.8-14.8 11.6-14.7.7-.9-2.1-1.6C69 41.4 52.6 48.7 43.8 42.2z"
                        className="ldl-ani"
                        opacity={0.1}
                        style={{
                          strokeWidth: 1,
                          fill: "#010101",
                        }}
                      />
                    </g>
                  </g>
                </g>
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g className="ldl-layer">
                      <g className="ldl-ani">
                        <g className="ldl-layer">
                          <g className="ldl-ani">
                            <g
                              className="ldl-layer"
                              style={{
                                opacity: 1,
                                transformOrigin: "50px 50px",
                                transform:
                                  "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                                animation:
                                  "1s linear -.8s infinite normal forwards running animate",
                                transformBox: "view-box",
                              }}
                            >
                              <path
                                fill="#FFF"
                                d="M56.1 27.3c-.6-.4-.7-1.3-.2-1.8l.5-.6c.4-.6 1.3-.7 1.8-.2.6.4.7 1.3.2 1.8l-.4.6c-.5.6-1.3.7-1.9.2z"
                                className="ldl-ani"
                                style={{
                                  strokeWidth: 1,
                                  fill: "#fff",
                                }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g className="ldl-layer">
                      <g className="ldl-ani">
                        <g className="ldl-layer">
                          <g className="ldl-ani">
                            <g
                              className="ldl-layer"
                              style={{
                                opacity: 1,
                                transformOrigin: "50px 50px",
                                transform:
                                  "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                                animation:
                                  "1s linear -.825s infinite normal forwards running animate",
                                transformBox: "view-box",
                              }}
                            >
                              <path
                                fill="#FFF"
                                d="M59.1 23.5c-.6-.4-.7-1.3-.2-1.8l2.9-3.7c.5-.6 1.3-.7 1.9-.2.6.5.7 1.3.2 1.9L61 23.3c-.5.6-1.3.7-1.9.2z"
                                className="ldl-ani"
                                style={{
                                  strokeWidth: 1,
                                  fill: "#fff",
                                }}
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g className="ldl-layer">
              <g className="ldl-ani">
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.85s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <path
                        fill="#E25E65"
                        d="M86.9 11.3c.4-2.5-.8-4.9-3-5.9-1.5-.8-3.2-.7-4.7 0l-11.7 5.4c-1.2.6-2.1 1.3-3 2.1l18.8 14.8c.6-1 1.2-2.2 1.4-3.4-.2-.2 2.2-13 2.2-13z"
                        className="ldl-ani"
                        style={{
                          strokeWidth: 1,
                          fill: "#eb5d21",
                        }}
                      />
                    </g>
                  </g>
                </g>
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.875s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <path
                        fill="#010101"
                        d="M85.8 10.5c-3.7 4.4-8.1 7.7-12.4 9.5l9.6 7.6c.6-1 1.2-2.2 1.4-3.4l2.5-12.8c.1-.6.2-1.4 0-2.1l-1.1 1.2z"
                        className="ldl-ani"
                        opacity={0.1}
                        style={{
                          strokeWidth: 1,
                          fill: "#010101",
                        }}
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g className="ldl-layer">
              <g className="ldl-ani">
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.9s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <path
                        fill="#eb5d21"
                        d="M71 43.1 58.3 58l.7.5 5.4 5.5 6.1-6.6c.7-1.1 1.1-2.3.9-3.6.1.1-.4-10.7-.4-10.7z"
                        className="ldl-ani"
                        style={{
                          strokeWidth: 1,
                          fill: "#eb5d21",
                        }}
                      />
                    </g>
                  </g>
                </g>
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.925s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <path
                        fill="#010101"
                        d="M71 43.1 58.3 58l.7.5 5.4 5.5 6.1-6.6c.7-1.1 1.1-2.3.9-3.6.1.1-.4-10.7-.4-10.7z"
                        className="ldl-ani"
                        opacity={0.1}
                        style={{
                          strokeWidth: 1,
                          fill: "#010101",
                        }}
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g className="ldl-layer">
              <g className="ldl-ani">
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.95s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <path
                        fill="#eb5d21"
                        d="m38 32-5 7.5 6.6 3.9.8.6L52 27.9 41 30c-1 .4-2 1-3 2z"
                        className="ldl-ani"
                        style={{
                          strokeWidth: 1,
                          fill: "#eb5d21",
                        }}
                      />
                    </g>
                  </g>
                </g>
                <g className="ldl-layer">
                  <g className="ldl-ani">
                    <g
                      className="ldl-layer"
                      style={{
                        opacity: 1,
                        transformOrigin: "50px 50px",
                        transform:
                          "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                        animation:
                          "1s linear -.975s infinite normal forwards running animate",
                        transformBox: "view-box",
                      }}
                    >
                      <path
                        fill="#010101"
                        d="M35.9 37.8c-.3-.3-.7-.5-.9-.9l-1.8 2.7 6.6 3.9.8.6 4.5-6.2c-3.5 1.6-6.9 1.7-9.2-.1z"
                        className="ldl-ani"
                        opacity={0.1}
                        style={{
                          strokeWidth: 1,
                          fill: "#010101",
                        }}
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g
              className="ldl-layer"
              style={{
                opacity: 1,
                transformOrigin: "50px 50px",
                transform: "matrix3d(.91,0,0,0,0,.91,0,0,0,0,.91,0,0,0,0,1)",
                animation:
                  "1s linear -1s infinite normal forwards running animate",
                transformBox: "view-box",
              }}
            >
              <circle
                cx={66.1}
                cy={29.6}
                r={5.1}
                fill="#A1C8D7"
                className="ldl-ani"
                style={{
                  strokeWidth: 1,
                  fill: "#e1e1e1",
                }}
                transform="rotate(-51.745 66.102 29.578)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <style>
      {
        "@keyframes animate{0%{transform:translate(0,0) rotate(0deg) scale(.91,.91) skew(0deg,0deg);opacity:1}4%{transform:translate(0,0) rotate(0deg) scale(.91,.91)}8%{animation-timing-function:cubic-bezier(.69,.6,.35,.27);transform:translate(0,0) rotate(0deg) scale(.91,.91)}14%{transform:translate(0,0) rotate(0deg) scale(.93,.93)}18%{transform:translate(0,0) rotate(0deg) scale(.94,.94)}22%{animation-timing-function:cubic-bezier(.67,.66,.34,.33);transform:translate(0,0) rotate(0deg) scale(.96,.96)}26%{transform:translate(0,0) rotate(0deg) scale(.97,.97)}30%{transform:translate(0,0) rotate(0deg) scale(.99,.99)}34%{animation-timing-function:cubic-bezier(.65,.71,.32,.38);transform:translate(0,0) rotate(0deg) scale(1.01,1.01)}40%{animation-timing-function:cubic-bezier(.64,.74,.31,.41);transform:translate(0,0) rotate(0deg) scale(1.02,1.02)}46%{animation-timing-function:cubic-bezier(.6,.91,.23,.63);transform:translate(0,0) rotate(0deg) scale(1.03,1.03)}50%,54%{transform:translate(0,0) rotate(0deg) scale(1.03,1.03)}58%{animation-timing-function:cubic-bezier(.69,.6,.35,.27);transform:translate(0,0) rotate(0deg) scale(1.03,1.03)}64%{transform:translate(0,0) rotate(0deg) scale(1.01,1.01)}68%{transform:translate(0,0) rotate(0deg) scale(1,1)}72%{animation-timing-function:cubic-bezier(.67,.66,.34,.33);transform:translate(0,0) rotate(0deg) scale(.98,.98)}76%{animation-timing-function:cubic-bezier(.66,.68,.33,.35);transform:translate(0,0) rotate(0deg) scale(.97,.97)}82%{animation-timing-function:cubic-bezier(.65,.71,.32,.38);transform:translate(0,0) rotate(0deg) scale(.94,.94)}88%{animation-timing-function:cubic-bezier(.65,.73,.31,.4);transform:translate(0,0) rotate(0deg) scale(.92,.92)}94%,to{animation-timing-function:cubic-bezier(.63,.8,.28,.48);transform:translate(0,0) rotate(0deg) scale(.91,.91)}}"
      }
    </style>
  </svg>
)
export default RocketIcon
