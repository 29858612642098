// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Base styles for loading animation */
/* Base styles for loading animation */
.loadingWrapper {
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--darkBlue);
}

.loadingImage {
  width: 200px;
  height: 200px;
  color: var(--textColor);
}

/* Dark Mode Styles */
.dark-mode {
  background-color: var(--darkBlue);
  color: var(--textColor);
}

`, "",{"version":3,"sources":["webpack://./src/loading.css"],"names":[],"mappings":"AAAA,sCAAsC;AACtC,sCAAsC;AACtC;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,qBAAqB;EACrB,eAAe;EACf,MAAM;EACN,OAAO;EACP,iCAAiC;AACnC;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;;AAEA,qBAAqB;AACrB;EACE,iCAAiC;EACjC,uBAAuB;AACzB","sourcesContent":["/* Base styles for loading animation */\n/* Base styles for loading animation */\n.loadingWrapper {\n  width: 100%;\n  height: 100vh;\n  display: grid;\n  place-content: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  background-color: var(--darkBlue);\n}\n\n.loadingImage {\n  width: 200px;\n  height: 200px;\n  color: var(--textColor);\n}\n\n/* Dark Mode Styles */\n.dark-mode {\n  background-color: var(--darkBlue);\n  color: var(--textColor);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
