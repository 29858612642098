// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ocean { 
  height: 0%;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #015871;
  opacity: 0.3;
  pointer-events: none;
}

.wave {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x; 
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 198px;
  animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.4;
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 0.4;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}`, "",{"version":3,"sources":["webpack://./src/wave.css"],"names":[],"mappings":";AACA;EACE,UAAU;EACV,WAAW;EACX,eAAe;EACf,SAAS;EACT,OAAO;EACP,mBAAmB;EACnB,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,qFAAqF;EACrF,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,aAAa;EACb,iEAAiE;EACjE,+BAA+B;EAC/B,YAAY;AACd;;AAEA;EACE,WAAW;EACX,uGAAuG;EACvG,YAAY;AACd;;AAEA;EACE;IACE,cAAc;EAChB;EACA;IACE,oBAAoB;EACtB;AACF;;AAEA;EACE;IACE,mCAAmC;EACrC;EACA;IACE,iCAAiC;EACnC;AACF","sourcesContent":["\n.ocean { \n  height: 0%;\n  width: 100%;\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  background: #015871;\n  opacity: 0.3;\n  pointer-events: none;\n}\n\n.wave {\n  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x; \n  position: absolute;\n  top: -198px;\n  width: 6400px;\n  height: 198px;\n  animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;\n  transform: translate3d(0, 0, 0);\n  opacity: 0.4;\n}\n\n.wave:nth-of-type(2) {\n  top: -175px;\n  animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;\n  opacity: 0.4;\n}\n\n@keyframes wave {\n  0% {\n    margin-left: 0;\n  }\n  100% {\n    margin-left: -1600px;\n  }\n}\n\n@keyframes swell {\n  0%, 100% {\n    transform: translate3d(0, -25px, 0);\n  }\n  50% {\n    transform: translate3d(0, 5px, 0);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
