// src/PriceChart.js
import './App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';

const PriceChart = ({ symbol, change, preloadedData }) => {
    console.log('PriceChart rendered with:', { symbol, change, preloadedDataLength: preloadedData?.length });
    
    // Return null if symbol is empty to prevent rendering
    if (!symbol) {
        return null;
    }

    const [chartData, setChartData] = useState([]);
    const [timeRange, setTimeRange] = useState(1); // Default to 1 day
    const [activeButton, setActiveButton] = useState(1); // Default active button

    useEffect(() => {
        console.log('PriceChart useEffect triggered:', {
            symbol,
            timeRange,
            hasPreloadedData: !!preloadedData,
            preloadedDataLength: preloadedData?.length
        });

        if (preloadedData?.length > 0 && timeRange === 1) {
            console.log('Setting preloaded chart data:', {
                dataLength: preloadedData.length,
                firstItem: preloadedData[0],
                lastItem: preloadedData[preloadedData.length - 1]
            });
            setChartData(preloadedData);
            return;
        }

        const fetchPriceData = async () => {
            try {
                const interval = timeRange === 1 ? '1h' : timeRange === 7 ? '1d' : timeRange === 30 ? '1d' : '7d';
                console.log('Fetching price data:', { symbol, timeRange, interval });
                
                const response = await axios.post('/api/cmc-historical', {
                    symbol,
                    convert: 'USD',
                    time_start: new Date(Date.now() - timeRange * 24 * 60 * 60 * 1000).toISOString(),
                    time_end: new Date().toISOString(),
                    interval
                });

                let processedData;
                if (response.data?.data?.quotes) {
                    processedData = response.data.data.quotes.map(entry => ({
                        date: new Date(entry.timestamp).toISOString(),
                        price: entry.quote?.USD?.price || 0
                    }));
                } else if (response.data?.data?.[symbol]) {
                    const tokenData = response.data.data[symbol];
                    processedData = tokenData[0].quotes.map(entry => ({
                        date: new Date(entry.timestamp).toISOString(),
                        price: entry.quote?.USD?.price || 0
                    }));
                }

                if (processedData?.length > 0) {
                    console.log('Setting processed chart data:', {
                        dataLength: processedData.length,
                        firstItem: processedData[0],
                        lastItem: processedData[processedData.length - 1]
                    });
                    setChartData(processedData);
                } else {
                    console.error('Invalid or empty chart data:', {
                        hasData: !!response.data?.data,
                        dataKeys: Object.keys(response.data?.data || {}),
                        responseStructure: JSON.stringify(response.data, null, 2)
                    });
                    setChartData([]);
                }
            } catch (error) {
                console.error('Error fetching price data:', {
                    message: error.message,
                    response: error.response?.data,
                    symbol,
                    timeRange
                });
                setChartData([]);
            }
        };

        fetchPriceData();
    }, [symbol, timeRange, preloadedData]);

    const handleButtonClick = (range) => {
        console.log('Time range button clicked:', { newRange: range, previousRange: timeRange });
        setTimeRange(range);
        setActiveButton(range);
    };

    console.log('Rendering chart with data:', {
        hasData: chartData?.length > 0,
        dataLength: chartData?.length,
        firstItem: chartData?.[0],
        lastItem: chartData?.[chartData?.length - 1]
    });

    return (
        <div data-testid="price-chart">
            <div className="chart-container">
                {chartData?.length > 0 ? (
                    <>
                        <div className="price-header">
                            <span className={`price-change ${parseFloat(change || 0) >= 0 ? 'positive' : 'negative'}`}>
                                {change ? `${change}%` : '0.00%'} (24h)
                            </span>
                        </div>
                        <ResponsiveContainer width="100%" height={400}>
                            <AreaChart
                                data={chartData}
                                margin={{
                                    top: 10, right: 30, left: 0, bottom: 0,
                                }}
                            >
                                <XAxis
                                    dataKey="date"
                                    tickFormatter={(date) => {
                                        const d = new Date(date);
                                        if (timeRange === 1) {
                                            return `${d.getHours()}:${d.getMinutes().toString().padStart(2, '0')}`;
                                        } else {
                                            return d.toLocaleDateString();
                                        }
                                    }}
                                />
                                <YAxis
                                    domain={['auto', 'auto']}
                                    tickFormatter={(value) => `$${value.toFixed(4)}`}
                                />
                                <Tooltip
                                    labelFormatter={(date) => new Date(date).toLocaleString()}
                                    formatter={(value) => [`$${value.toFixed(6)}`, 'Price']}
                                />
                                <Area
                                    type="monotone"
                                    dataKey="price"
                                    stroke="#167194"
                                    fill="url(#areaGradient)"
                                    fillOpacity={0.3}
                                />
                                <defs>
                                    <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="0%" stopColor="#167194" stopOpacity={0.4} />
                                        <stop offset="100%" stopColor="#167194" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                            </AreaChart>
                        </ResponsiveContainer>
                        <div className="time-range-buttons">
                            <button
                                className={`time-button ${activeButton === 1 ? 'active' : ''}`}
                                onClick={() => handleButtonClick(1)}
                            >
                                24H
                            </button>
                            <button
                                className={`time-button ${activeButton === 7 ? 'active' : ''}`}
                                onClick={() => handleButtonClick(7)}
                            >
                                7D
                            </button>
                            <button
                                className={`time-button ${activeButton === 30 ? 'active' : ''}`}
                                onClick={() => handleButtonClick(30)}
                            >
                                30D
                            </button>
                        </div>
                    </>
                ) : (
                    <div className="no-data">Loading chart data...</div>
                )}
            </div>
        </div>
    );
};

export default PriceChart;